import { ElementRef, OnInit, Renderer2, ViewChild, Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NativeService } from "../providers/NativeService";
import { _require } from '../providers/require_lib';
import { HttpService } from "../providers/HttpService"
import { DomSanitizer } from '@angular/platform-browser';
import { Utils } from "../providers/Utils"
import { WeChatShareService } from "../providers/weChatShareService";
declare let Swiper: any;
declare let $: any;
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-goods-detail',
  templateUrl: './goods-detail.component.html',
  styleUrls: ['./goods-detail.component.css', '../../assets/css/swiper.min.css', '../../assets/css/animate.min.css', '../../assets/css/coupon.css']
})
export class GoodsDetailComponent implements OnInit {
  public showView = false;
  public stockid = 0;
  public shopid = 0;
  public innerWidth = 0;
  public activeIndex = 1;
  public titleList = ["商品详情", "包装参数"];
  public activeId = 0;
  public dataDict: any = {};
  public piclistCount = 0;
  public packlistCount = 0;
  public packlist = [];
  public sanitizeHtml: any;
  public isLogin = false;
  public cartTotalNum = 0;
  public buyNow = false;
  public innerHeight = 0;
  public tagsList = [];

  public showWXShareTip = false;
  public mShareLinkParams = '';
  public showShareLinkParams = '';
  public sharepic;
  public isShare = false;
  public shareLink = "";
  public showAnimation = false;
  public sharePic = "";
  public goodsList = [];

    // 
    public ticketList;
    public isShow: boolean = true;
  @ViewChild('mySwiper1') mySwiper1: ElementRef;
  public myStyle;
  @ViewChild('topbox') topbox: ElementRef;
  @ViewChild('navHeight') navHeight: ElementRef;
  constructor(public titleServers:Title,public shareService: WeChatShareService, public renderer: Renderer2, public utils: Utils, public sanitize: DomSanitizer, public httpService: HttpService, public router: Router, public routeInfo: ActivatedRoute, public nativeService: NativeService) { }
  backToPage() {
    this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
      this.nativeService.backToPage();
    });
  }

  ngOnInit() {
    this.innerHeight = window.innerWidth;
    //接收参数
    this.routeInfo.queryParams.subscribe(queryParams => {
      this.stockid = queryParams.stockid == undefined ? 0 : queryParams.stockid;
      this.shopid = queryParams.shopid == undefined ? 0 : queryParams.shopid;
      // this.shopid = queryParams.shopid == undefined ? this.utils.getLocalStorageItem('shopid') : queryParams.shopid;
      // this.utils.setLocalStorageItem('shopid', this.shopid);
    });
    this.isLogin = this.utils.getLocalStorageItem("isLogin");
    this.nativeService.showLoading();
    if (this.isLogin) {
      this.getCartTotalData();
    }
    this.getGoodsInfo();
    this.getTicketList();
  }
  // 分享
  public shareConfig() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      let params = {
        'openalias': 'wap.share.wxshare.getWxSign',
        'url': window.location.href,//window.location.href,
        'plat': 'plat_wap',
        'openno': '1',
      }
      this.httpService.request({
        'method': 'GET',
        'url': 'api/index',
        'data': params
      }).then(result => {
        this.nativeService.hideLoading();
        if (result.code == 100 && result.data) {
          var data = {
            appId: result.data.appId,
            timestamp: result.data.timestamp,
            nonceStr: result.data.nonceStr,
            signature: result.data.signature,
          };
          this.shareService.configWXJs(data);
          var title =  this.utils.getLocalStorageItem('shopInfo').shopname!= undefined ? this.utils.getLocalStorageItem('shopInfo').shopname: "爱车爱生活 爱上基利斯";
          this.shareService.getShareDataAndReadyLink(this.dataDict.title,
            this.sharePic, title
            , "", location.href).subscribe(
              (success) => {
                //分享bug:分享成功和取消都返回1
                console.log(success);
              });
        }
      });
    }
  }
  public isWeixin() {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf('micromessenger') != -1;
    if (isWeixin) {
      return true;
    } else {
      return false;
    }
  }
  shareClick() {
    if (this.isWeixin()) {
      this.showWXShareTip = true;
      setTimeout(() => {
        this.showWXShareTip = false;
        this.isShare = false;
        this.showAnimation = false;
      }, 6000);
    } else {
      this.sharepic = this.dataDict.expand.pic;
      var tip = "点击链接或者复制链接到浏览器打开!!!";
      this.mShareLinkParams = '【' + this.dataDict.title + '】' + window.location.href + tip;
      this.showShareLinkParams = this.dataDict.title;
      this.isShare = true;
      this.showAnimation = true;
    }
  }
  public shareCopylink() {
    this.showAnimation = false;
    this.nativeService.showSuccessAlert("复制成功,快去粘贴分享吧~");
    setTimeout(() => {
      this.isShare = false;
    }, 800);
  }
  public successFun() {
    // this.nativeService.showSuccessAlert("复制成功,快去粘贴吧~");
  }
  public cancelShare() {
    this.showAnimation = false;
    setTimeout(() => {
      this.isShare = false;
    }, 800);
  };
  public showWXShareTipClick() {
    this.showWXShareTip = false;
    this.isShare = false;
  }

  // 轮播图
  swiperInit() {
    var that = this;
    _require('wap/assets/js/swiper.min.js', function () {
      /* swiper初始化 */
      var mySwiper = new Swiper('.swiper-container', {
        autoplay: 0,
        loop: false,
        pagination: '.swiper-pagination',
        autoplayDisableOnInteraction: false,
        paginationClickable: true,
        longSwipesRatio: 0.3,
        touchRatio: 1,
        autoHeight: false,
        observer: true,//修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,//修改swiper的父元素时，自动初始化swiper
        onSlideChangeEnd: function (swiper) {
          that.activeIndex = swiper.activeIndex + 1;
        }
      });
    });
    setTimeout(() => {
      that.renderer.setStyle(that.mySwiper1.nativeElement, 'height', that.innerHeight + 'px');
    }, 50);
  }
  //获取店铺信息
  getGoodsInfo() {
    var param = {};
    if (this.isLogin) {
      param = {
        'plat': 'plat_wap',
        'openno': '2',
        'openalias': 'wap.product.productmall.getStorkInfo',
        'stockid': this.stockid,
        'userid': this.utils.getLocalStorageItem("user_info").userid,
        'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
        'shopid': this.shopid
      };
    } else {
      param = {
        'plat': 'plat_wap',
        'openno': '2',
        'openalias': 'wap.product.productmall.getStorkInfo',
        'stockid': this.stockid,
        'shopid': this.shopid
      };
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        success.data.expand.detail = success.data.expand.detail.replace(/\<img/gi, '<img style="max-width:100%;display:block;"');
        this.dataDict = success.data;
        this.dataDict.integralpriceLimit = this.utils.priceLimit(success.data.integralprice);
        this.piclistCount = success.data.piclist.length;
        this.packlistCount = success.data.expand.packlist.length;
        this.packlist = success.data.expand.packlist;
        this.sanitizeHtml = this.sanitize.bypassSecurityTrustHtml(success.data.expand.detail);
        this.sharePic = this.dataDict.expand.pic + ".thumbnail.jpg";
        this.showView = true;
        this.dealTagsData(success.data);
        this.swiperInit();
        this.shareConfig();
        var title = this.dataDict.title;
        this.titleServers.setTitle(title);
        // 推荐商品
        if (success.data.recommends != "" && success.data.recommends != null && success.data.recommends.length > 0) {
          success.data.recommends.forEach(element => {
            element.integralpriceLimit = this.utils.priceLimit(element.integralprice);
          });
          this.goodsList = success.data.recommends;
        }

        //计算锚点高度
        setTimeout(() => {
          var topboxHeight = this.topbox.nativeElement.clientHeight;
          this.myStyle = {
            "height": topboxHeight + "px"
          }
          var navHeight = $("#navHeight").offset().top - 45;
          var navFix = $("#nav-wrap");
          $(window).scroll(function () {
            if ($(this).scrollTop() > navHeight) {
              navFix.addClass("navFix");
            } else {
              navFix.removeClass("navFix");
            }
          })
          $('.nav-wrap').navScroll({
            mobileDropdown: true,
            mobileBreakpoint: 768,
            scrollSpy: true
          });
        }, 300);

      } else {
        this.nativeService.showErrorAlert(success.msg);
      }
      this.nativeService.hideLoading();
    });
  }
  //处理标签
  dealTagsData(data) {
    this.tagsList = [];
    data.goodsactivity.forEach(element => {
      element.activitytype = 2;
      element.enjoy = element.check.status;
      this.tagsList = this.tagsList.concat(element);
    });
    data.orderactivity.forEach(element => {
      element.activitytype = 1;
      element.enjoy = element.check.status;
      this.tagsList = this.tagsList.concat(element);
    });
  }
  //购物车商品数量
  getCartTotalData() {
    var param = {
      'plat': 'plat_wap',
      'openno': '2',
      // 'openalias': this.shopid == 0 ? "wap.cart.mallcart.getCartTotal" : "wap.cart.shopcart.getCartTotal",
      'openalias': "wap.cart.shopcart.getCartTotal",
      'shopid': this.shopid,
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
    }
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        this.cartTotalNum = success.data;
      } else {
        // this.nativeService.showErrorAlert(success.msg);
        if (success.msg == "登录已过期,请重新登录" || success.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
          this.isLogin = false;
          this.cartTotalNum = 0;
        }
      }
    });
  }
  //加入购物车
  addCarAndBuyNow() {
    var that = this;
    if (that.dataDict['shopstock'] > 0) {
      this.nativeService.showLoading();
      let  param = {
            'plat': 'plat_wap',
            'openno': '2',
            'openalias': "wap.cart.shopcart.add",
            'userid': this.utils.getLocalStorageItem("user_info").userid,
            'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
            'pid': that.stockid,
            'num': 1,
            'shopid': that.shopid,
            'productType': 1
          }
      // if (this.shopid == 0) {
      //   var param = {};
      //   param = {
      //     'plat': 'plat_wap',
      //     'openno': '2',
      //     'openalias': "wap.cart.mallcart.add",
      //     'userid': this.utils.getLocalStorageItem("user_info").userid,
      //     'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      //     'pid': that.stockid,
      //     'num': 1
      //   }
      // } else {
      //   param = {
      //     'plat': 'plat_wap',
      //     'openno': '2',
      //     'openalias': "wap.cart.shopcart.add",
      //     'userid': this.utils.getLocalStorageItem("user_info").userid,
      //     'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      //     'pid': that.stockid,
      //     'num': 1,
      //     'shopid': that.shopid,
      //     'productType': 1
      //   }
      // }
      this.httpService.request({
        method: 'POST',
        url: 'api/index',
        data: param,
      }).then(success => {
        if (success.code == 100 && success.data) {
          that.getCartTotalData();
          if (that.buyNow) {
            if (that.shopid == 0) {
              // this.router.navigate(['goodsCart'], { queryParams: { stockid: that.stockid } });
              this.router.navigate(['shopCar'], { queryParams: { stockid: that.stockid } });
            } else {
              this.router.navigate(['shoppingCart'], { queryParams: { shopid: this.shopid, pkey: that.stockid + "_1" } });
            }
            this.buyNow = false;
          } else {
            this.nativeService.showSuccessAlert("已成功加入购物车");
          }
        } else {
          this.nativeService.showErrorAlert(success.msg);
        }
        this.nativeService.hideLoading();
      });
    } else {
      this.nativeService.showWarningAlert("商品已售完");
    }
  }
  //标签点击
  tagClcik(item) {
    var json = JSON.stringify({
      "activeId": 2
    });
    this.utils.sessionStorageSetItem("enterShopdetailInitData", json);
    this.router.navigate(['shopActivity'], { queryParams: { aid: item.aid } });
  }
  //点击tabs
  tabsClick(selectIndex) {
    this.activeId = selectIndex;
  }
  //首页
  goHome() {
    this.router.navigate(['index']);
  }
  goToCar() {
    if (this.whetherLogin()) {
      this.utils.removeLocalStorageItem('isNewLoading');
      if (this.shopid == 0) {
        // this.router.navigate(['goodsCart'], { queryParams: { stockid: 0 } });
        this.router.navigate(['shopCar'], { queryParams: { stockid: 0 } });
      } else {
        this.router.navigate(['shoppingCart'], { queryParams: { shopid: this.shopid } });
      }
    }
  }
  addToCar() {
    if (this.whetherLogin()) {
      this.addCarAndBuyNow();
    }
  }
  buyNowClick() {
    if (this.whetherLogin()) {
      this.buyNow = true;
      this.addCarAndBuyNow();
    }
  }
  whetherLogin() {
    if (!this.isLogin) {
      this.utils.setLocalStorageItem('isWechatAuth', false);
      this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
      this.router.navigate(['login']);
      return false;
    } else {
      return true;
    }
  }
  // 进入s商品详情
  public gotoGoodsDetails(item) {
    this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
      this.router.navigate(['goodsDetail'], { queryParams: { stockid: item.stockid, shopid: this.shopid } });
    });
  }

  public getTicketList() {
    let param = {
      'plat': 'plat_wap',
      'openno': '1',
      'pchannel': 3,
      'openalias': 'wap.ticket.GetUseTicket.getList',
      'userid': this.utils.getLocalStorageItem('user_info').userid,
      'user_token': this.utils.getLocalStorageItem('user_info').usertoken,
      'productid': this.stockid,//产品id
      'shopid': this.shopid,//领取的门店ID
      'producttype': 2
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        let arr = result.data;
        for(let i =0; i < arr.length;i++){
          arr[i].hidden = true;
        }
        this.ticketList = arr;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    });
  }

  public openCoupon() {
    console.log(1);
    this.isShow = false;
  }
  public toClose() {
    this.isShow = true;
  }

  public getVoucher(pid){
    let param = {
      'plat': 'plat_wap',
      'openno': '1',
      'pchannel': 3,
      'openalias': 'wap.ticket.Ticketuser.addTicket',
      'userid': this.utils.getLocalStorageItem('user_info').userid,
      'user_token': this.utils.getLocalStorageItem('user_info').usertoken,
      'shareId': pid,//产品id
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.nativeService.showSuccessAlert('领取成功！')
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    });
  }

  public showDetails(index){
    this.ticketList[index].hidden = !this.ticketList[index].hidden;
  }

}
