import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NativeService } from "../providers/NativeService";
import { HttpService } from '../providers/HttpService';
import { Utils } from '../providers/Utils';
import { DataServerService } from "./../providers/DataServer";
@Component({
  selector: 'app-ticket-order',
  templateUrl: './ticket-order.component.html',
  styleUrls: ['./ticket-order.component.css']
})
export class TicketOrderComponent implements OnInit {

  public goodNum = 1;
  public ticketId = '';
  public bindCarList = [];
  public shopId = 0;
  public userticketid = '';
  public ticketObj;
  public productList;
  public ticketList;

  constructor(
    public nativeService: NativeService,
    public utils: Utils,
    public http: HttpService,
    public routeInfo: ActivatedRoute,
    public router: Router,
    public dataServer: DataServerService
  ) { }

  ngOnInit() {
    this.userticketid = this.utils.getLocalStorageItem("ServicePakageCoupon").userticketid;
    this.routeInfo.queryParams.subscribe(query => {
      this.ticketId = query.id ? query.id : '';
      this.shopId = query.sid ? query.sid : 0;
    })
    console.log(this.dataServer.data);
    if (this.dataServer.data.length > 0) {
      this.bindCarList = this.dataServer.data;
    }
    this.getDetail();
    
  }

  public getDetail() {
    let params = {
      'plat': 'plat_wap',
      'openno': '2',
      'pchannel': 3,
      'openalias': 'wap.cart.Saasticketcart.index',
      'userid': this.utils.getLocalStorageItem('user_info').userid,
      'user_token': this.utils.getLocalStorageItem('user_info').usertoken,
      'pid': this.ticketId, //通用券id
      'num': this.goodNum, //数量
      'userTicketId': this.userticketid
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100) {
        this.ticketObj = result.data.suborder[0];
        this.ticketList = result.data.suborder[0].ticketlist;
        this.productList = result.data.suborder[0].productlist;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }

  public backToPage() {
    this.dataServer.data = [];
    this.nativeService.backToPage();
  }

  public goodsAdd() {
    this.goodNum += 1;
  }
  public goodsDel() {
    if (this.goodNum == 1) {
      this.nativeService.showErrorAlert('最少为1喔');
      return;
    }
    this.goodNum -= 1;
  }

  public goOrderPay() {
    var bindvehicles = '';
    if (this.productList[0].bindvehiclestatus == 2 && this.bindCarList.length == 0) {
      this.nativeService.showWarningAlert("至少选择一辆车绑定");
      return;
    } else {
      for (var index = 0; index < this.bindCarList.length; index++) {
        if (index == 0) {
          bindvehicles += this.bindCarList[index].cartnum;
        } else {
          bindvehicles += ',' + this.bindCarList[index].cartnum;
        }

      }
    }
    let param = {
      'openno': '2',
      'pchannel': 3,
      'userid': this.utils.getLocalStorageItem('user_info').userid,
      'user_token': this.utils.getLocalStorageItem('user_info').usertoken,
      'openalias': 'wap.cart.Saasticketcart.createorder',// wap.order.Orderbuilder.saasticket
      'plat': 'plat_wap',//平台
      'stockids': this.ticketId,//商品库存ID
      'num': this.goodNum,//数量
      'shopid': this.shopId,//下单门店id
      'bindvehicles': bindvehicles,//绑定车辆 多个逗号隔开
      'userticketid': this.userticketid,//券id
      'remark': '',//备注
      'cityid': '',//城市id
    };
    this.http.request({
      method: 'POST',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        console.log(result.data);
        this.dataServer.data = [];
        this.utils.removeLocalStorageItem('ServicePakageCoupon');
        this.router.navigate(['orderPay'], {
          queryParams: {
            'orderid': result.data.orderid,
          }
        });
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    });
  }

  /* lq */
  // 删除绑定车辆
  public delBindCar(index) {
    console.log(index);
    // let index = e.currentTarget.dataset.index
    let newArr = this.bindCarList;
    newArr.splice(index, 1);
    this.bindCarList = newArr;
    this.dataServer.data = newArr;
  }
  // 添加绑定数量
  public addBindCarNums() {
    // /pages/vehicleManagement/vehicleList/vehicleList?isSelected=true
    this.router.navigate(['my-car'], { queryParams: { isSelected: true } })
  }

  //选择优惠券
  selectCouponList() {
    var productlists = this.productList;
    var pids_ptypes = productlists[0].pid + ',6';
    var pidStrKey = productlists[0].pid + '_6';
    var tl = this.ticketList == undefined ? '[]' : JSON.stringify(this.ticketList);
    this.router.navigate(['discountCouponSelect'], { queryParams: { 'productlist': pids_ptypes, 'shopid': 0, 'ticketlist': tl, 'pidStr': pidStrKey, 'isServicePakage': true } });
  }

}
