import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NativeService } from "../providers/NativeService";
import { HttpService } from '../providers/HttpService';
import { Utils } from '../providers/Utils';
@Component({
  selector: 'app-ticket-detail',
  templateUrl: './ticket-detail.component.html',
  styleUrls: ['./ticket-detail.component.css', '../../assets/css/coupon.css']
})
export class TicketDetailComponent implements OnInit {

  public pageIndex = 1;
  public ticketId = '';
  public ticketObj;
  public storeInfo;
  public formatted_address = '';
  public getLocationSuccess = true;
  public shopId = 0;
  // 
  public isLogin;
  public sellPrice: any = '0.00';
  // 
  public ticketList;
  public isShow: boolean = true;
  constructor(
    public nativeService: NativeService,
    public utils: Utils,
    public http: HttpService,
    public routeInfo: ActivatedRoute,
    public router: Router
  ) { }

  ngOnInit() {
    var isLogin = this.utils.getLocalStorageItem("isLogin");
    if (isLogin == undefined || isLogin == null || isLogin == '') {
      this.isLogin = false;
    } else {
      this.isLogin = true;
    }
    this.routeInfo.queryParams.subscribe(query => {
      this.ticketId = query.id ? query.id : '';
      this.shopId = query.shopid ? query.shopid : 0;
    })
    this.getTicketDetail();
    this.getTicketList();
  }
  // 获取通用券详情
  public getTicketDetail() {
    let param = {
      'plat': 'plat_wap',
      'openno': '2',
      'shopid': this.shopId,//门店id
      'id': this.ticketId,//通用券id
      'lng': this.utils.getLocalStorageItem('lng'),//百度经度 ========经纬度同时存在值时 会计算门店与当前位置距离 并按距离排序
      'lat': this.utils.getLocalStorageItem('lat'),//百度纬度 ======== 经纬度不传时则不计算距离 并且不返回距离字段
      'openalias': 'wap.saas.Ticket.getInfo',//wap.saas.Ticket.getInfo
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        console.log(result.data);
        let isLogin = this.utils.getLocalStorageItem("isLogin");
        let isVipUser = this.utils.getLocalStorageItem("user_info").isVipUser;
        if (result.data.isvipprice == 2 && isLogin && isVipUser == 1) {
          this.sellPrice = result.data.vipprice;
        } else {
          this.sellPrice = result.data.sellprice;
        }
        this.ticketObj = result.data;
        this.storeInfo = result.data.shopInfo;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    });
  }

  public backToPage() {
    this.nativeService.backToPage();
  }
  public goPageIndex1() {
    this.pageIndex = 1;
  }
  public goPageIndex2() {
    this.pageIndex = 2;
  }
  //拨打电话
  public callNumber() {
    window.location.href = "tel:" + this.storeInfo['tel'];
  }
  public goShopList() {
    this.pageIndex = 2;
  }
  public openLocation() {

  }
  public refreshAddress() {

  }
  // 门店导航
  addressClick() {
    //直接调起腾讯地图
    var txMap = this.bMapTransQQMap(this.storeInfo['blng'], this.storeInfo['blat']);
    var latitude = txMap.lat;
    var longitude = txMap.lng;
    var address = this.storeInfo['addressdetail'];
    var shopName = this.storeInfo['shopname'];
    window.location.href = "https://3gimg.qq.com/lightmap/v1/marker/index.html?type=0&marker=coord%3A" + latitude + "%2C" + longitude + "%3Btitle%3A" + shopName + "%3Baddr%3A" + address + "%2F12&referer=jilisi&key=YJ4BZ-DHGWR-JEOWH-WQV63-6Z356-QLBTX";
  }
  //百度经纬度转腾讯地图经纬度
  public bMapTransQQMap(lng, lat) {
    let x_pi = 3.14159265358979324 * 3000.0 / 180.0;
    let x = lng - 0.0065;
    let y = lat - 0.006;
    let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_pi);
    let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_pi);
    let lngs = z * Math.cos(theta);
    let lats = z * Math.sin(theta);
    return {
      lng: lngs,
      lat: lats
    }
  }

  public goTicketPay() {
    if (!this.isLogin) {
      this.router.navigate(['login']);
      return;
    }
    if(this.shopId==0){
      this.router.navigate(['ticketOrder'], { queryParams: {id: this.ticketId,sid: this.ticketObj.shopid} });
    }else{
      this.router.navigate(['ticketOrder'], { queryParams: {id: this.ticketId,sid: this.shopId} });
    }
  }

  public getTicketList() {
    let param = {
      'plat': 'plat_wap',
      'openno': '1',
      'pchannel': 3,
      'openalias': 'wap.ticket.GetUseTicket.getList',
      'userid': this.utils.getLocalStorageItem('user_info').userid,
      'user_token': this.utils.getLocalStorageItem('user_info').usertoken,
      'productid': this.ticketId,//产品id
      'shopid': '0',//领取的门店ID
      'producttype': '7'
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        let arr = result.data;
        for(let i =0; i < arr.length;i++){
          arr[i].hidden = true;
        }
        this.ticketList = arr;
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    });
  }

  public openCoupon() {
    this.isShow = false;
  }
  public toClose() {
    this.isShow = true;
  }

  public getVoucher(pid){
    let param = {
      'plat': 'plat_wap',
      'openno': '1',
      'pchannel': 3,
      'openalias': 'wap.ticket.Ticketuser.addTicket',
      'userid': this.utils.getLocalStorageItem('user_info').userid,
      'user_token': this.utils.getLocalStorageItem('user_info').usertoken,
      'shareId': pid,//产品id
    };
    this.http.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.nativeService.showSuccessAlert('领取成功！')
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    });
  }

  public showDetails(index){
    this.ticketList[index].hidden = !this.ticketList[index].hidden;
  }

}
