//bar-门店购物车
import { Component, OnInit } from '@angular/core';
import { NativeService } from "../providers/NativeService";
import { HttpService } from "../providers/HttpService"
import { Utils } from "../providers/Utils"
import { Router } from '@angular/router';
import { RELEASE } from '../providers/Constants';
@Component({
  selector: 'app-shop-car',
  templateUrl: './shop-car.component.html',
  styleUrls: ['./shop-car.component.css']
})
export class ShopCarComponent implements OnInit {

  public shopid = 0;
  public isLogin = false;
  public showLoading = false;
  public numtotal = 0;
  public productlist = [];
  public productlistWL = [];
  public pkeyList = [];
  public shopData = {};
  public carListData: any = {};
  public amountpay = 0.00; //总价格
  public amountdiscount = 0.00; //已优惠价格
  public editeNow = false;
  public allSelcted = false;
  public showView = false;

  public ticketList;
  // 是否切换物流配送方式isSHowSwitchDialog
  public isSHowSwitchDialog = false;
  public modeOfDistributionArr = [
    { 'name': '物流配送发货', 'type': 2, 'isSelect': false },
    { 'name': '到店自提/服务', 'type': 1, 'isSelect': false }
  ]
  constructor(public nativeService: NativeService, public httpService: HttpService, public router: Router, public utils: Utils) {
    // if (this.utils.getLocalStorageItem('shopid') == undefined || this.utils.getLocalStorageItem('shopid') == null) {
    //   if (!RELEASE) {
    //     this.utils.setLocalStorageItem('shopid', 1);
    //   } else {
    //     this.utils.setLocalStorageItem('shopid', 5);
    //   }
    // }
    // this.shopid = this.utils.getLocalStorageItem('shopid')
  }

  ngOnInit() {
    this.isLogin = this.utils.getLocalStorageItem("isLogin");
    if (this.isLogin) {
      //已登陆
      this.showLoading = true;
      this.nativeService.showLoading();
      // this.getCarListData();
      if (this.utils.getLocalStorageItem("isNewLoading") && this.utils.getLocalStorageItem("isNewLoading") == 'false') {
        this.getCarListData();
      } else {
        this.onInitShopCar();
      }
    }
  }
  //获取购物车列表
  getCarListData() {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '2',
      'openalias': 'wap.cart.shopcart.getCart',
      'productids': 0,
      'shopid': this.shopid,
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        that.numtotal = success.data.numtotal;
        that.carListData = success.data;

        // console.log("购物车数据:"+JSON.stringify(success.data));
        // that.productlist = carGoodsList;

        var suborder = success.data.suborder;
        that.ticketList = suborder[0].ticketlist;
        // console.log("suborder---:"+JSON.stringify(suborder));
        that.productlist = [];
        that.productlistWL = [];
        if (suborder != undefined || suborder != null) {
          let length = suborder.length;
          if (suborder[0].tip == "以下商品需到店自提") {
            that.productlist = this.dataFiltration(suborder[0].numtotal, suborder[0].orderdislist, suborder[0].productlist);
          } else {
            that.productlistWL = this.dataFiltration(suborder[0].numtotal, suborder[0].orderdislist, suborder[0].productlist);
          }
          if (length > 1) {
            if (suborder[1].tip == "以下商品需到店自提") {
              that.productlist = this.dataFiltration(suborder[1].numtotal, suborder[1].orderdislist, suborder[1].productlist);
            } else {
              that.productlistWL = this.dataFiltration(suborder[1].numtotal, suborder[1].orderdislist, suborder[1].productlist);
            }
          }
          // that.productlistWL.forEach(element => {
          //   if(element.integralprice>0){
          //     element.sellprice2 = (element.sellprice-element.integralprice).toFixed(2);
          //   }else{
          //     element.sellprice2 = element.sellprice;
          //   }
          // });
        }
        // console.log("productlist---:"+JSON.stringify(that.productlist));
        // console.log("productlistWL---:"+JSON.stringify(that.productlistWL));
        // console.log("car:" + JSON.stringify(that.pkeyList));
        // that.getShopDetailData();
        that.reDeailData();
      } else {
        this.nativeService.showErrorAlert(success.msg);
        that.showLoading = false;
        that.nativeService.hideLoading();
      }
    });
  }
  // 筛选数据
  public dataFiltration(numtotal, orderdislist, productlist) {
    var carGoodsList = [];
    var pkeyList = [];
    //满减的商品
    if (numtotal > 0 && orderdislist.length > 0) {
      for (var i = 0; i < orderdislist.length; i++) {
        var orderdisItem = orderdislist[i];
        orderdisItem.showDisstatus = true;
        orderdisItem.list = [];
        for (var j = 0; j < orderdisItem.stock.length; j++) {
          for (var k = 0; k < productlist.length; k++) {
            productlist[k].pkey = productlist[k].key;
            var productlistItem = productlist[k];
            // productlistItem.pkey = productlistItem.key;
            if (productlistItem.pkey == orderdisItem.stock[j]) {
              orderdisItem.list.push(productlistItem);
              pkeyList.push(productlistItem.pkey);//把有满减的商品的kpey放在数组里
            }
          }
        }
        carGoodsList.push(orderdisItem);
      }
      //挑选剩下没有满减的商品
      var orderdisItem: any = {
        list: [],
        showDisstatus: false
      };
      //比较两个数组找出pkey不同的商品放在一个数组里面
      var result = [];
      for (var m = 0; m < productlist.length; m++) {
        var obj = productlist[m];
        var pkey = obj.pkey;
        var isExist = false;
        for (var n = 0; n < pkeyList.length; n++) {
          var aj = pkeyList[n];
          if (aj == pkey) {
            isExist = true;
            break;
          }
        }
        if (!isExist) {
          result.push(obj);
        }
      }
      //再遍历加入商品
      for (var l = 0; l < result.length; l++) {
        orderdisItem.list.push(result[l]);
      }
      if (orderdisItem.list.length > 0) {
        carGoodsList.push(orderdisItem);
      }
    } else {
      //没有满减的时候直接加入数组
      var orderdisItem: any = {
        list: [],
        showDisstatus: false
      };
      for (var l = 0; l < productlist.length; l++) {
        var productlistItem = productlist[l];
        productlistItem.pkey = productlistItem.key;
        orderdisItem.list.push(productlistItem);
      }
      if (orderdisItem.list.length > 0) {
        carGoodsList.push(orderdisItem);
      }
    }


    // console.log(carGoodsList);

    // 优惠券处理
    let carListArry = carGoodsList;
    let ticketArry = this.ticketList;
    if (ticketArry && ticketArry.length > 0) {
      for (let d = 0; d < ticketArry.length; d++) {
        for (let n = 0; n < carListArry[0].list.length; n++) {
          if (ticketArry[d].productkey == carListArry[0].list[n].key) {
            carListArry[0].list[n].ticketPrice = ticketArry[d].price
          }
        }
      }
    }
    // console.log(carListArry);


    return carGoodsList;
  }
  getShopDetailData() {
    var that = this;
    var param = {
      'plat': 'plat_wap',
      'openno': '1',
      'openalias': 'wap.shop.shop.shopInfo',
      'productids': 0,
      'shopid': that.shopid,
    };
    this.httpService.request({
      method: 'GET',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        // console.log("success.data:"+JSON.stringify(success.data))
        var province = success.data.province == null ? "" : success.data.province;
        var cityname = success.data.cityname == null ? "" : success.data.cityname;
        var areaname = success.data.areaname == null ? "" : success.data.areaname;
        var streetname = success.data.streetname == null ? "" : success.data.streetname;
        var addressdetail = success.data.addressdetail == null ? "" : success.data.addressdetail;
        success.data.shopLongAddress = province + cityname + areaname + streetname + addressdetail;
        that.shopData = success.data;
      }
      that.showLoading = false;
      that.nativeService.hideLoading();
      that.showView = true;
    });
  }
  reDeailData() {
    var productlist = this.productlist;
    var pkeyList = this.pkeyList;
    for (var i = 0; i < productlist.length; i++) {
      var pList = productlist[i].list;
      for (var j = 0; j < pList.length; j++) {
        var listItem = pList[j];
        for (var k = 0; k < pkeyList.length; k++) {
          if (pkeyList[k] == listItem.pkey) {
            productlist[i].list[j].selected = true;
          }
        }
      }
    }
    var productlistWL = this.productlistWL;
    for (var i = 0; i < productlistWL.length; i++) {
      var pList = productlistWL[i].list;
      for (var j = 0; j < pList.length; j++) {
        var listItem = pList[j];
        for (var k = 0; k < pkeyList.length; k++) {
          if (pkeyList[k] == listItem.pkey) {
            productlistWL[i].list[j].selected = true;
          }
        }
      }
    }
    this.productlist = productlist;
    this.productlistWL = productlistWL;
    this.getSlectGoodsPriceData();
    this.showView = true;
  }
  getSlectGoodsPriceData() {
    var that = this;
    // console.log("111111");
    if (that.numtotal > 0) {
      if (that.pkeyList.length > 0) {
        var that = this;
        var param = {
          'plat': 'plat_wap',
          'openno': '2',
          'openalias': 'wap.cart.shopcart.getCart',
          'productids': that.pkeyList.join(","),
          'shopid': that.shopid,
          'userid': this.utils.getLocalStorageItem("user_info").userid,
          'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
        };
        this.httpService.request({
          method: 'GET',
          url: 'api/index',
          data: param,
        }).then(success => {
          if (success.code == 100 && success.data) {
            // console.log("success:"+JSON.stringify(success));
            that.carListData = success.data;
            that.amountpay = success.data.amountpay;//总价格
            that.amountdiscount = parseFloat(success.data.orderdiscount) + parseFloat(success.data.amountticket); //已优惠价格
            // console.log("dddd"+ that.amountdiscount);
          } else {
            // console.log("6666"+success.msg);
            // this.nativeService.showErrorAlert(success.msg);
          }
          that.showLoading = false;
          that.nativeService.hideLoading();
        });
      } else {
        that.amountpay = 0.00; //总价格
        that.amountdiscount = 0.00; //已优惠价格
        that.showLoading = false;
        that.nativeService.hideLoading();
      }
    }
    // that.showLoading = false;
  }
  goodsDeal(item, add) {
    var that = this;
    var pItem = item;
    var pAdd = add;
    // that.showLoading = true;
    var param = {};
    if (add) {
      param = {
        'plat': 'plat_wap',
        'openno': '2',
        'userid': this.utils.getLocalStorageItem("user_info").userid,
        'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
        'openalias': "wap.cart.shopcart.add",
        'pid': item.pid,
        'shopid': that.shopid,
        'num': 1,
        'productType': item.ptype
      }
    } else {
      param = {
        'plat': 'plat_wap',
        'openno': '2',
        'userid': this.utils.getLocalStorageItem("user_info").userid,
        'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
        'openalias': "wap.cart.shopcart.reduce",
        'pid': item.pkey,
        'shopid': that.shopid,
        'num': 1,
      }
    }
    this.httpService.request({
      method: 'POST',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        //当商品减到0把pkey数组对应的pkey移走
        if (!pAdd && (pItem.num - 1) == 0) {
          for (var i = 0; i < that.pkeyList.length; i++) {
            if (that.pkeyList[i] == pItem.pkey) {
              that.pkeyList.splice(i, 1)
            }
          }
        }
        console.log("rm:" + JSON.stringify(that.pkeyList));
        that.getCarListData();
      } else {
        this.nativeService.showErrorAlert(success.msg);
      }
      this.showLoading = false;
      this.nativeService.hideLoading();
    });
  }
  //选择单个商品
  singleClick(item, type) {
    if (type == 2) {
      var productlistWL = this.productlistWL;
      for (var i = 0; i < productlistWL.length; i++) {
        var pList = productlistWL[i].list;
        for (var j = 0; j < pList.length; j++) {
          var listItem = pList[j];
          if (listItem.pkey == item.pkey) {
            productlistWL[i].list[j].selected = !productlistWL[i].list[j].selected;
            if (productlistWL[i].list[j].selected) {
              this.pkeyList.push(item.pkey);
            } else {
              for (var k = 0; k < this.pkeyList.length; k++) {
                if (this.pkeyList[k] == item.pkey) {
                  this.pkeyList.splice(k, 1);
                }
              }
            }
          }
        }
      }
      this.productlistWL = productlistWL;
      // console.log("pkeyList:"+JSON.stringify(this.pkeyList));
      // console.log("productlistWL:"+JSON.stringify(this.productlistWL));
    } else {
      var productlist = this.productlist;
      for (var i = 0; i < productlist.length; i++) {
        var pList = productlist[i].list;
        for (var j = 0; j < pList.length; j++) {
          var listItem = pList[j];
          if (listItem.pkey == item.pkey) {
            productlist[i].list[j].selected = !productlist[i].list[j].selected;
            if (productlist[i].list[j].selected) {
              this.pkeyList.push(item.pkey);
            } else {
              for (var k = 0; k < this.pkeyList.length; k++) {
                if (this.pkeyList[k] == item.pkey) {
                  this.pkeyList.splice(k, 1);
                }
              }
            }
          }
        }
      }
      this.productlist = productlist;
    }
    this.allSelcted = false;
    this.showLoading = true;
    this.nativeService.showLoading();
    this.getSlectGoodsPriceData();
  }
  //商品详情
  itemClick(item) {
    if (item.ptype == 1) {
      //商品
      this.router.navigate(['goodsDetail'], { queryParams: { stockid: item.pid, shopid: this.shopid } });
    } else if (item.ptype == 2) {
      //服务
      this.router.navigate(['serviceDetail'], { queryParams: { serviceid: item.pid, shopid: this.shopid } });
    } else {
      //套餐
      this.router.navigate(['packageDetail'], { queryParams: { setmealid: item.pid, shopid: this.shopid } });
    }
  }
  //凑单
  tagClcik(item) {
    if (!item.ret) {
      var json = JSON.stringify({
        "activeId": 2
      });
      this.utils.sessionStorageSetItem("enterShopdetailInitData", json);
      this.router.navigate(['shopActivity'], { queryParams: { aid: item.aid } });
    }
  }
  //减少数量
  removeToCar(item, idx) {
    this.goodsDeal(item, false);
  }
  //添加数量
  addToCar(item, idx) {
    this.goodsDeal(item, true);
  }
  //全选
  allClick() {
    this.showLoading = true;
    this.nativeService.showLoading();
    var allSelcted = !this.allSelcted;
    this.pkeyList = [];
    var productlist = this.productlist;
    for (var i = 0; i < productlist.length; i++) {
      var pList = productlist[i].list;
      for (var j = 0; j < pList.length; j++) {
        var listItem = pList[j];
        productlist[i].list[j].selected = allSelcted;
        if (allSelcted) {
          this.pkeyList.push(listItem.pkey);
        } else {
          for (var k = 0; k < this.pkeyList.length; k++) {
            if (this.pkeyList[k] == listItem.pkey) {
              this.pkeyList.splice(k, 1);
            }
          }
        }
      }
    }

    var productlistWL = this.productlistWL;
    for (var i = 0; i < productlistWL.length; i++) {
      var pList = productlistWL[i].list;
      for (var j = 0; j < pList.length; j++) {
        var listItem = pList[j];
        productlistWL[i].list[j].selected = allSelcted;
        if (allSelcted) {
          this.pkeyList.push(listItem.pkey);
        } else {
          for (var k = 0; k < this.pkeyList.length; k++) {
            if (this.pkeyList[k] == listItem.pkey) {
              this.pkeyList.splice(k, 1);
            }
          }
        }
      }
    }
    this.productlistWL = productlistWL;
    this.productlist = productlist;
    this.allSelcted = allSelcted;
    this.getSlectGoodsPriceData();
    console.log(this.pkeyList);
  }
  //编辑/完成
  editClick() {
    this.editeNow = !this.editeNow;
  }
  //结算/删除
  commitClick() {
    if (this.editeNow) {
      //删除
      this.delectClick();
    } else {
      //结算
      this.payClick();
    }
  }
  //删除
  delectClick() {
    var that = this;
    var pidStr = that.pkeyList.join(",");
    if (pidStr.length > 0) {
      this.showLoading = true;
      this.nativeService.showLoading();
      var param = {
        'plat': 'plat_wap',
        'openno': '2',
        'openalias': 'wap.cart.shopcart.del',
        'pid': pidStr,
        'shopid': that.shopid,
        'userid': this.utils.getLocalStorageItem("user_info").userid,
        'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      };
      this.httpService.request({
        method: 'GET',
        url: 'api/index',
        data: param,
      }).then(success => {
        if (success.code == 100 && success.data) {
          this.nativeService.showSuccessAlert("删除成功");
          that.amountpay = 0.00; //总价格
          that.amountdiscount = 0.00; //已优惠价格
          that.allSelcted = false;
          that.pkeyList = [];
          that.getCarListData();
        } else {
          this.nativeService.showErrorAlert(success.msg);
        }
        that.showLoading = false;
        that.nativeService.hideLoading();
      });
    } else {
      this.nativeService.showWarningAlert("至少选择一个商品");
    }
  }
  //结算
  payClick() {
    var that = this;
    var pidStr = that.pkeyList.join(",");
    if (pidStr.length > 0) {
      this.utils.setLocalStorageItem('pidStr', pidStr);
      this.utils.removeLocalStorageItem('isNewLoading');
      this.router.navigate(['ShopOrderConfirm'], { queryParams: { shopid: that.shopid, pidStr: pidStr } })
    } else {
      this.nativeService.showWarningAlert("至少选择一个商品");
    }
  }

  //去登陆
  gotoLogin() {
    this.utils.setLocalStorageItem('isWechatAuth', false);
    this.utils.setLocalStorageItem('isWechatAuthGetKey', false);
    this.router.navigate(['login']);
  }

  // 切换配送方式
  // 切换配送方式
  public dismissSwitchDialog() {
    this.isSHowSwitchDialog = false;
  }
  public selectType = 0;
  public productData: any;
  public trigger(itemData, type) {
    this.selectType = type;
    this.productData = itemData;
    // console.log(JSON.stringify(itemData));
    let index = 1;
    for (let index = 0; index < this.modeOfDistributionArr.length; index++) {
      if (this.modeOfDistributionArr[index].type == type) {
        this.modeOfDistributionArr[index].isSelect = true;
      } else {
        this.modeOfDistributionArr[index].isSelect = false;
      }
    }
    this.isSHowSwitchDialog = true;
    if (index == 1) {
      return;
    }

  }

  // 选择配送方式
  public selectLogisticsMode(idx) {
    for (let index = 0; index < this.modeOfDistributionArr.length; index++) {
      this.modeOfDistributionArr[index].isSelect = false;
    }
    this.modeOfDistributionArr[idx].isSelect = true;
  }
  // 选择完成
  public done() {
    let isEqual = false;
    for (let index = 0; index < this.modeOfDistributionArr.length; index++) {
      if (this.modeOfDistributionArr[index].isSelect) {
        if (this.modeOfDistributionArr[index].type == this.selectType) {
          isEqual = true;
        }
      }
    }
    this.isSHowSwitchDialog = false;
    if (!isEqual) {
      this.goTrigger();
    }
  }

  public goTrigger() {
    let that = this;
    this.showLoading = true;
    this.nativeService.showLoading();
    let param = {
      'plat': 'plat_wap',
      'openno': '2',
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
      'openalias': "wap.cart.shopcart.change",
      'pkey': this.productData.key,
      'shopid': this.utils.getLocalStorageItem("shopid"),
    }
    this.httpService.request({
      method: 'POST',
      url: 'api/index',
      data: param,
    }).then(success => {
      if (success.code == 100 && success.data) {
        if (success.data.ret) {
          that.pkeyList = [];
          that.nativeService.showSuccessAlert('切换成功');
        }
        that.getCarListData();
      } else {
        this.nativeService.showErrorAlert(success.msg);
      }
      this.showLoading = false;
      this.nativeService.hideLoading();
    });
  }
  //选择优惠券
  public selectCouponList(item) {
    var pids_ptypes = item.pid + ',1';
    var tl = this.ticketList == undefined ? '[]' : JSON.stringify(this.ticketList);
    this.router.navigate(['discountCouponSelect'], { queryParams: { 'productlist': pids_ptypes, 'shopid': 0, 'ticketlist': tl, 'pidStr': item.key, 'isServicePakage': false } });
  }


  // 优惠券
  public onInitTicket(pkey) {
    let pid = pkey.replace(',', '/').replace('_', ',');
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.cart.shopcart.getUseList',
      'shopid': 0,
      'productlist': pid,
      'plat': 'plat_wap',
      'openno': '2',
    }
    this.httpService.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      this.nativeService.hideLoading();
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
        let arr = result.data;
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].issupport) {
            this.setCouponAboutOrder(arr[i].id, pkey);
            return;
          }
        }
      } else {
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }

  //使用优惠券
  public setCouponAboutOrder(tid, pid) {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.cart.shopcart.addTicket',
      'userTicketId': tid,
      'plat': 'plat_wap',
      'openno': '2',
      'pidstr': pid,
      'shopid': 0,
    }
    this.httpService.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
      } else {
        this.nativeService.hideLoading();
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }

  // 获取购物车
  public onInitShopCar() {
    var params = {
      'plat': 'plat_wap',
      'openno': '2',
      'openalias': 'wap.cart.shopcart.getCart',
      'productids': 0,
      'shopid': 0,
      'userid': this.utils.getLocalStorageItem("user_info").userid,
      'user_token': this.utils.getLocalStorageItem("user_info").usertoken,
    };
    this.httpService.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        // console.log(JSON.stringify(result));
        if (result.data.hasOwnProperty("suborder") && result.data.suborder.length > 0) {
          let arry = result.data.suborder[0].productlist;
          for (let i = 0; i < arry.length; i++) {
            this.onInitTicket(arry[i].key);
          }
          setTimeout(() => {
            this.getCarListData();
          }, 1000)
          this.utils.setLocalStorageItem("isNewLoading", "false");
        }
      } else {
        this.nativeService.hideLoading();
        this.nativeService.showErrorAlert(result.msg);
      }
    })
  }

}



