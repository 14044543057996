import { Component, OnInit } from '@angular/core';
import { _require } from '../providers/require_lib';
import { HttpService } from "../providers/HttpService"
import { Router } from "@angular/router";
import { Utils } from '../providers/Utils'
import { NativeService } from '../providers/NativeService';

@Component({
  selector: 'app-welfare-list',
  templateUrl: './welfare-list.component.html',
  styleUrls: ['./welfare-list.component.css']
})
export class WelfareListComponent implements OnInit {
  public giftData;
  public giftList;

  constructor(public http: HttpService, public router: Router, public utils: Utils, public nativeService: NativeService) { }

  ngOnInit() {
    this.getList();
  }

  public backToPage() {
    this.nativeService.backToPage();
  }

  // 获取列表
  public getList() {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.vip.MemberManage.memberGift',
      'plat': 'plat_wap',
      'openno': '2',
      'shopid': this.utils.getLocalStorageItem('shopid')
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.giftData = result.data;
        this.giftList = result.data.list;
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
        }
      }
    })
  }
  // 
  public setItem(tid) {
    let params = {
      'userid': this.utils.getLocalStorageItem("user_id"),
      'user_token': this.utils.getLocalStorageItem("user_token"),
      'openalias': 'wap.vip.MemberManage.getGiftTicket',
      'plat': 'plat_wap',
      'openno': '2',
      'ticketid': tid,
      'shopid': this.utils.getLocalStorageItem('shopid')
    }
    this.http.request({
      'method': 'GET',
      'url': 'api/index',
      'data': params
    }).then(result => {
      if (result.code == 100 && result.data) {
        this.nativeService.showSuccessAlert('领取成功！');
        setTimeout(() => {
          this.router.navigate(['discountCoupon'], { queryParams: { 'shopid': this.utils.getLocalStorageItem('shopid') } });
        }, 1500)
      } else {
        this.nativeService.showWarningAlert(result.msg);
        if (result.msg == "登录已过期,请重新登录" || result.msg == "请登录") {
          this.utils.setLocalStorageItem("isLogin", false);
        }
      }
    })
  }

}
