import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
//外部组件
import { InfiniteScrollModule } from 'ngx-infinite-scroll';//上拉加载更多==下拉刷新有问题不要用
//自定义组件
import { FixedNavModule } from "./component/fixed-nav/fixed-nav.module";//导航栏
import { DataShowModule } from "./component/data-show/data-show.module";//
import { AddressSelectionModule } from './component/address-selection/address-selection.module';
// 其他服务
import { ApiParamMD5 } from "./providers/ApiParamMD5"
import { HttpService } from './providers/HttpService';
import { Utils } from './providers/Utils';
import { GlobalData } from './providers/GlobalData';
import { NativeService } from './providers/NativeService';
import { Routes } from './app.routes';
import { WeChatShareService } from "./providers/weChatShareService";
//控制器component和module
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { ProductModule } from './product/product.module';
import { ShopCarModule } from './shop-car/shop-car.module';
import { MineModule } from './mine/mine.module';
import { GoodsModule } from './goods/goods.module';
import { GoodsDetailModule } from './goods-detail/goods-detail.module';
import { SearchModule } from './search/search.module';
import { ShopDetailModule } from './shop-detail/shop-detail.module';
import { ServiceDetailModule } from './service-detail/service-detail.module';
import { PackageDetailModule } from './package-detail/package-detail.module';
import { ShoppingCartModule } from './shopping-cart/shopping-cart.module';
import { OrderConfirmModule } from './order-confirm/order-confirm.module';
import { MimeQrcodeModule } from './mime-qrcode/mime-qrcode.module';
import { MallOrdersModule } from './mall-orders/mall-orders.module';
import { ShopOrdersModule } from './shop-orders/shop-orders.module';
import { GoodsServicesModule } from './goods-services/goods-services.module';
import { MineAddressModule } from './mine-address/mine-address.module';
import { AddAddressModule } from './add-address/add-address.module';
import { ShopAreaSelectModule } from './shop-area-select/shop-area-select.module';
import { RegisterModule } from './register/registerd.module';
import { LoginModule } from './login/login.module';
import { ForgetPasswordModule } from './forget-password/forget-password.module';
import { GoodsCartModule } from './goods-cart/goods-cart.module';
import { ShopOrderConfirmModule } from './shop-order-confirm/shop-order-confirm.module';
import { InvoiceInfoModule } from './invoice-info/invoice-info.module';
import { PayResultsModule } from './pay-results/pay-results.module';
import { MallOrderDetailsModule } from './mall-order-details/mall-order-details.module';
import { ShopOrderDetailsModule } from './shop-order-details/shop-order-details.module';
import { LogisticsDetailsModule } from './logistics-details/logistics-details.module';
import { OrderPayModule } from './order-pay/order-pay.module';
import { IframeShowModule } from './iframe-show/iframe-show.module';
import { DiscountCouponModule } from './discount-coupon/discount-coupon.module';
import { CanGiveTicketModule } from './can-give-ticket/can-give-ticket.module';
import { DiscountCouponSelectModule } from './discount-coupon-select/discount-coupon-select.module';
import { CouponRedemptionCentreModule } from './coupon-redemption-centre/coupon-redemption-centre.module';
import { WechatLoginGetkeyModule } from './wechat-login-getkey/wechat-login-getkey.module';
import { CouponDetailModule } from './coupon-detail/coupon-detail.module';
import { SelectShopModule } from './select-shop/select-shop.module';
import { PackageListModule } from './package-list/package-list.module';
import { ServiceListModule } from './service-list/service-list.module';
import { ShopActivityModule } from './shop-activity/shop-activity.module';
import { CouponFriendsShareModule } from './coupon-friends-share/coupon-friends-share.module';
import { CouponActivityShareModule } from './coupon-activity-share/coupon-activity-share.module';
import { ShareholderModule } from './shareholder/shareholder/shareholder.module' ;//股东分红
import { BuyShareholderModule } from './shareholder/buy-shareholder/buy-shareholder.module' ;//股东分红（未入股）
import { AccountCenterModule } from './account-center/account-center.module' ;//账户中心
import { WalletModule } from './wallet/wallet.module' ;//钱包、余额
import { MyCarModule } from './my-car/my-car.module' ;//我的车辆
import { AboutUsModule } from './about-us/about-us.module' ;//关于我们;
import { ServicePackageOrderConfirmModule } from './service-package-order-confirm/service-package-order-confirm.module';//服务套餐确认订单
import { GeneralizeDetailModule } from './shareholder/generalize-detail/generalize-detail.module';//推广积客券详情
import { MonthGiftModule } from './shareholder/month-gift/month-gift.module';//月月有礼
import { WelfareCentreModule } from './shareholder/welfare-centre/welfare-centre.module';//福利中心
import { WelfareCentreDetialModule } from './shareholder/welfare-centre-detial/welfare-centre-detial.module';//福利中心详情
import { MineIntegralModule } from './mine-integral/mine-integral.module';//积分
import { TicketDetailModule } from './ticket-detail/ticket-detail.module';
import { TicketOrderModule } from './ticket-order/ticket-order.module';
import { ActivityPageModule } from './activity-page/activity-page.module';
import { MyInfoModule } from './my-info/my-info.module' ; //我的资料
import { ShopPhotosModule } from './shop-photos/shop-photos.modules';//店铺相册;

import { CouponShareModule } from './coupon-share/coupon-share.module'//分享券

// 2021.5.18 中石化加油
import { SROrderDetailModule } from './SR-order-detail/SR-order-detail.module';//订单详情
import { SROrderConfirmModule } from './SR-order-confirm/SR-order-confirm.module';//提交订单;
import { SRListDetailModule } from './SR-list-detail/SR-list-detail.module';//列表详情
import { SinopecRefuelingModule } from './sinopecRefueling/sinopecRefueling.module';//中石化列表订单;

// 2022.1.10 基利斯平台化
import { TabIndexModule } from './tab-index/tab-index.module';// 首页
import { SetMealListModule } from './set-meal-list/set-meal-list.module';// 套餐列表
import { ServeListModule } from './serve-list/serve-list.module';//服务列表
import { ServeSetMealDetailModule } from './serve-set-meal-detail/serve-set-meal-detail.module';//套餐服务详情;
import { ShopListModule } from './shopList/shopList.module'; //门店列表
import { TicketListModule } from './ticketList/ticketList.module'; //通用券;
import { TicketOrdersModule } from './ticket-orders/ticket-orders.module';//通用券订单列表
// 2022.08.15 vip模块
import { InviteFriendsModule } from './invite-friends/invite-friends.module';//邀请好友
import { JlsVipModule } from './jls-vip/jls-vip.module';//注册购买
import { WelfareListModule } from './welfare-list/welfare-list.module';//会员福利
import { DisCouoonDetailModule } from './dis-couoon-detail/dis-couoon-detail.module';
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(Routes,{useHash:true}),//{useHash:true} 必须是true 不然把链接copy出来打开会找不到控制器https://juejin.im/post/5a39c10351882538d3101129
    FixedNavModule,
    HttpModule,
    HttpClientModule,
    InfiniteScrollModule,
    DataShowModule,
    HomeModule,
    ProductModule,
    ShopCarModule,
    MineModule,
    AddressSelectionModule,
    GoodsModule,
    GoodsDetailModule,
    SearchModule,
    ShopDetailModule,
    ServiceDetailModule,
    PackageDetailModule,
    ShoppingCartModule,
    OrderConfirmModule,
    MimeQrcodeModule,
    MallOrdersModule,
    ShopOrdersModule,
    GoodsServicesModule,
    MineAddressModule,
    AddAddressModule,
    ShopAreaSelectModule,
    LoginModule,
    RegisterModule,
    ForgetPasswordModule,
    GoodsCartModule,
    ShopOrderConfirmModule,
    InvoiceInfoModule,
    PayResultsModule,
    MallOrderDetailsModule,
    ShopOrderDetailsModule,
    LogisticsDetailsModule,
    OrderPayModule,
    IframeShowModule,
    DiscountCouponModule,
    CanGiveTicketModule,
    DiscountCouponSelectModule,
    WechatLoginGetkeyModule,
    CouponRedemptionCentreModule,
    CouponDetailModule,
    SelectShopModule,
    PackageListModule,
    ServiceListModule,
    ShopActivityModule,
    CouponFriendsShareModule,
    CouponActivityShareModule,
    ShareholderModule,
    BuyShareholderModule,
    AccountCenterModule,
    WalletModule,
    MyCarModule,
    AboutUsModule,
    ServicePackageOrderConfirmModule,
    GeneralizeDetailModule,
    MonthGiftModule,
    WelfareCentreModule,
    WelfareCentreDetialModule,
    MineIntegralModule,
    ShopPhotosModule,
    CouponShareModule,
    TicketDetailModule,
    TicketOrderModule,
    ActivityPageModule,
    MyInfoModule,
    SROrderDetailModule,
    SROrderConfirmModule,
    SRListDetailModule,
    SinopecRefuelingModule,
    TabIndexModule,
    SetMealListModule,
    ServeListModule,
    ServeSetMealDetailModule,
    ShopListModule,
    TicketListModule,
    TicketOrdersModule,
    InviteFriendsModule,
    JlsVipModule,
    WelfareListModule,
    DisCouoonDetailModule
  ],
  exports: [RouterModule],
  providers: [
    HttpService,
    Utils,
    GlobalData,
    NativeService,
    ApiParamMD5,WeChatShareService],
  bootstrap: [AppComponent]
})
export class AppModule { }
